.checkout_page {
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  .checkout_header {
    text-align: center;
    margin: 1rem 0 4rem 0;

    h2 {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
  }

  .checkout_message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span {
      margin-right: 0.4rem;
    }
  }

  .checkout_total {
    display: flex;
    justify-content: flex-end;
    font-size: 22px;
    font-weight: 600;
    padding-top: 4rem;
  }

  .checkout_button {
    display: flex;
    margin-top: 2rem;
    justify-content: flex-end;
    
    button {
      display: flex;
      align-items: center;
      border: 1px solid var(--secondary);
      background-color: var(--secondary);
      line-height: normal;

      &:hover {
        color: white;
        border: 1px solid #0074d4;
        background-color: #0074d4;
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
      }
      
      span {
        font-size: 20px;
        margin: 0 0.5rem;
      }

      > div {
        margin-right: 12px;
      }
    }

    button:disabled,
    button[disabled] {
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
      cursor: not-allowed;
    }
  }
}

@media (min-width: 450px) {
  .checkout_page .checkout_message {
    flex-direction: row;
  }
}