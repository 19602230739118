.sign_in {
  margin: 0 auto;
  max-width: 500px;
  padding: 3rem;
  
  .title {
    margin-bottom: 1rem;
  }
}

.sign_in_form {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
}

.sign_in_form_buttons {
  display: flex;
  margin-top: 1.4rem;
  flex-direction: column;

  button:first-child {
    margin-bottom: 1.4rem;
  }
}

.sign_up_button {
  margin-top: 2rem;
  padding-top: 2rem;
  text-align: center;
  border-top: 1px solid var(--border);

  span {
    cursor: pointer;
    font-weight: bold;
    color: var(--primary);
    
    a {
      color: var(--primary);
    }
    
    &:hover {
      text-decoration: underline;
    }
  }
}

.sign_error {
  font-size: 13px;
  color: var(--primary);
  margin-top: 1rem;
}

.signin_forgot_password {
  text-align: center;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
  }
}

@media (min-width: 768px) {
  .sign_in {
    padding-top: 2rem;
  }
}

@media (min-width: 815px) {
  .sign_in_form_buttons {
    flex-direction: row;
    justify-content: space-between;
  }
}