.cart_floating_button_container {
  right: 8vw;
  bottom: 20vh;

  z-index: 9;
  position: fixed;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cart_floating_button {
  width: 74px;
  height: 74px;
  border-radius: 60px;
  background-color: var(--purple);

  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid rgba(76, 2, 136, 0.6);
  box-shadow: 0 4px 14px 0 rgba(129, 9, 224, 0.6);
  
  span {
    color: white;
    font-size: 2rem;
  }
}

.cart_floating_button_tag {
  z-index: 99;
  color: white;
  padding: 3px 6px;
  border-radius: 5px;
  margin-top: -55px;
  position: absolute;
  margin-right: -55px;
  background-color: var(--primary);
}

@media (min-width: 768px) {
  .cart_floating_button_container {
    right: 4vw;
    bottom: 10vh;
  }
}