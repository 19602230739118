
.cart_button {
  display: none;
}

@media (min-width: 768px) {
  .cart_button {
    display: flex;
    align-items: center;
    color: var(--default);
    font-size: 14px;
  
    span {
      font-size: 38px;
      margin-right: 5px;
    }
  }
}