.card_tag {
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;

  display: flex;
  padding: 0.4rem;
  align-items: center;

  span {
    font-size: 18px;
    margin-right: 3px;
  }
}

.card_tag_top_left {
  float: left;
  border-radius: 10px 0 10px 0;
}

.card_tag_top_right {
  float: right;
  border-radius: 0 10px 0 10px;
}

.card_tag_color_primary {
  background-color: var(--primary);
}

.card_tag_color_secondary {
  background-color: var(--secondary);
}