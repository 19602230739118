@mixin shrinkLabel {
  top: -18px;
  font-size: 13px;
  color: var(--negrito);
}

.form_group {
  position: relative;

  input[type="password"] {
    letter-spacing: 0.3em;
  }
}

.form_input {
  display: block;
  padding: 10px 10px 10px 5px;
  margin: 1.4rem 0;

  border: none;
  background-color: transparent;
  border-bottom: 1px solid var(--default);
  
  width: 100%;
  font-size: 18px;
  color: var(--default);

  &:focus {
    outline: none;
  }

  &:focus ~ .form_input_label {
    @include shrinkLabel();
  }
}

.form_input_label {
  left: 5px;
  top: 5px;
  font-size: 16px;
  position: absolute;
  color: var(--default);
  pointer-events: none;
  transition: 300ms ease all;
  text-transform: capitalize;

  margin-top: 1.62rem;

  &.shrink {
    @include shrinkLabel();
  }
}
