.button {
  width: auto;
  height: 50px;
  padding: 0 1.2rem;
  min-width: 120px;
  
  color: white;
  font-size: 15px;
  font-weight: bold;
  line-height: 50px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  
  border: none;
  cursor: pointer;
  border-radius: 6px;
  background-color: var(--negrito);

  &:hover {
    color: var(--negrito);
    background-color: white;
    border: 1px solid var(--negrito);
  }

  &.google_sign_in {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:white;
    color: var(--default);
    border: 1px solid var(--default);
    line-height: normal;
    
    &:hover {
      color: var(--secondary);
      border: 1px solid var(--secondary);
    }

    svg {
      width: 22px;
      height: auto;
      margin-right: 1rem;
    }
  }
}