.homepageMessage {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

@media (min-width: 768px) {
  .homepageMessage {
    flex-direction: row;
  }
}