.downloadContainer {
  width: 100%;

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
}

.downloadHeader {
  margin-bottom: 4rem;
  h2 {
    margin: 2rem 0;
  }
  p {
    text-align: center;
  }
}

.downloadButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: var(--secondary);
  }

  span {
    margin-left: 1rem;
  }
}

.downloadCell {
  width: 210px;
}

.downloadMessage {
  text-align: center;
  margin-top: 2rem;
}

.downloadMessageIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  span {
    margin-right: 1rem;
  }
}