.login_button {
  img {
    width: 22px;
    height: 22px;
    border-radius: 100px;
    margin-right: 8px;
  }
  
  &:hover .login_dropdown_content {
    display: flex;
    flex-direction: column;

    a {
      padding: 0.5rem;
      color: var(--secondary);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.login_dropdown_content {
  z-index: 1;
  display: none;
  padding: 1rem;
  min-width: 145px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 20px 0px var(--border);
}

.login_dropdown_button {
  display: flex;
  align-items: center;
  color: var(--negrito);

  &:hover {
    text-decoration: none;
  }
}

.login_button_sign_in a {
  display: flex;
  align-items: center;
}