.card_button {
  cursor: pointer;
  border-radius: 6px;
  background-color: var(--purple);
  
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;

  padding: 0.85rem;
  width: 122px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--dark-purple);
  }

  span {
    font-size: 18px;
    margin-right: 5px;
  }
}

.card_button_normal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.card_button_offer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
  // margin-top: -34px;
}

.card_price {
  color: var(--primary);
  font-size: 14px;
  font-weight: bold;
  border-radius:  0 6px 6px 0;
  padding: 0.3em;
  background-color:yellow;
}

.card_discount {
  display: flex;
  align-items:center;
  
  color: white;
  font-size: 13px;
  font-weight: bold;
  padding: 0.3rem;
  background-color: var(--success);
  border-radius: 6px 0 0 6px;
}

.card_price_offer {
  display: flex;
  align-items:center;
  margin-bottom: 0.5rem;
}