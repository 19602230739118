.side_navigation {
  display: grid;
  min-height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 1fr;
}

.side_navigation_title {
  margin: 3rem 0;
}

.side_navigation_footer {
  border-top: 1px solid var(--border);
}