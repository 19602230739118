@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

.logotipo {
  a {
    display: flex;
    align-items: center;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    width: 70px;
    height: 70px;

    border-radius: 100px;
    border: 3px solid rgba(129, 9, 224, 0.25);
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2);
  }

  h1 {
    display: none;
    font-size: 20px;
    margin-left: 16px;
    font-family: 'Roboto Condensed', sans-serif;
  }
}

@media (min-width: 600px) {
  .logotipo h1 {
    display: initial;
  }
}