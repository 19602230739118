.accountContainer {
  width: 100%;
}

.accountHeader {
  margin-bottom: 4rem;
  h2 {
    margin: 2rem 0;
  }
  p {
    text-align: center;
  }
}

.accountContainerButtons {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.accountButton {
  padding: 2rem;
  display: flex;
  cursor: pointer;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--purple);
  margin: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--purple);
  text-transform: uppercase;
  
  &:hover {
    color: white;
    text-decoration: none;
    background-color: var(--purple);
    box-shadow: 0 2px 7px 0 rgba(129, 9, 224, 0.2);
  }

  span {
    font-size: 2.4rem;
    margin-right: 1rem;
  }
}