.grid_products {
  display: flex;
  grid-gap: 2rem 0;
  flex-direction: column;
}

@media (min-width: 600px) {
  .grid_products {
    display: grid;
    grid-gap: 3.8rem 2rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 0fr);
  }
}

@media (min-width: 996px) {
  .grid_products {
    grid-template-columns: repeat(3, 1fr);
  }
}