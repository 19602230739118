.layout_grid {
  display: grid;
  padding-top: 0;
  min-height: 100vh;
  padding-bottom: 1.6rem;
  grid-template-columns: 1fr;
  grid-template-rows: 84px 1fr 60px;
}

.layout_topo {
  margin: 0.4rem 1.6rem 0;
  border-bottom: 1px solid var(--border);
}

.layout_lateral {
  display: none;
  padding: 1rem 1.6rem;
}

.layout_content {
  display: grid;
  // margin: 0 auto;
  grid-template-columns: 100vw;
  grid-template-rows: 1fr 200px;
}

.layout_content_container {
  margin: 2rem;
  display: flex;
  justify-content: center;
}

.layout_bottom {
  bottom: 0;
  width: 100vw;
  height: 60px;
  position: fixed;
}

@media (min-width: 768px) {
  .layout_grid {
    grid-template-columns: 247px 1fr;
    grid-template-rows: 84px 1fr;
  }
  
  .layout_topo {
    grid-column: 1/3;
  }

  .layout_content {
    grid-template-columns: 100%;
  }

  .layout_lateral {
    top: 0px;
    height: 100vh;
    position: sticky;
    display: initial;
    border-right: 1px solid var(--border);
  }

  .layout_bottom {
    display: none;
  }
}