.navigation_button {
  margin: 1.6rem 0;
  
  a {
    display: flex;
    font-size: 16px;
    color: var(--default);
    align-items: center;
    text-transform: capitalize;
    
    &:hover {
      font-weight: 600;
      color: var(--negrito);
      text-decoration: none;
    }
  }

  span {
    font-size: 22px;
    margin-right: 8px;
  }

  .inverted {
    color: white;
    
    &:hover {
      color: var(--border);
    }
  }
}

