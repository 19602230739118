.search_box {
  display: none;
  height: 45px;
  padding-top: 2px;

  border-radius: 5px;
  background-color: #f8f8f8;
  border: 1px solid var(--border);
  
  &:hover {
    border: 1px solid var(--default);
  }

  input[type=search] {
    width: 100%;
    height: 40px;
    border: none;
    font-size: 14px;
    margin-right: 5px;
    border-radius: 5px;
    color: var(--default);
    background-color: #f8f8f8;

    &:focus {
      outline: none;
    }
  }

  button {
    border: none;
    margin: 0 12px;
    cursor: pointer;
    color: var(--default);
    background-color: #f8f8f8;
  }
}

@media (min-width: 768px) {
  .search_box {
    width: 20vw;
    display: flex;
  }
}

@media (min-width: 815px) {
  .search_box {
    width: 24vw;
  }
}

@media (min-width: 900px) {
  .search_box {
    width: 32vw;
  }
}