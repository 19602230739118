.navigation_fixed_container {
  bottom: 0;
  min-width: 100%;
  padding: 8px 0;
  position: absolute;
  background-color: var(--negrito);

  ul {
    display: flex;
    justify-content: space-evenly;
  }

  li, a {
    display: flex;
    color: white;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    
    p {
      margin-top: 2px;
      font-size: 12px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}