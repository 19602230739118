.card {
  max-height: 100%;
  max-width: 300px;
  border: 1px solid var(--border);
  background-color: white;
  border-radius: 12px;
  text-align: center;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 14px 0 rgba(129, 9, 224, 0.2);
  }
}

.card_offer {
  display: initial;
  border: 1px solid var(--primary);
}

.card_image {
  img {
    height: auto;
    max-width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin: auto 1px;
  }

  p {
    font-size: 14px;
    margin-bottom: 12px;
    padding: 1rem;
    text-transform: capitalize;
  }
}