.sign_up {
  margin: 0 auto;
  max-width: 500px;
  padding: 3rem;
  
  .title {
    margin-bottom: 1rem;
  }
}

.sign_up_form {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
}

.sign_up_form_buttons {
  margin-top: 1.4rem;

  button {
    width: 100%;
  }
}

.sign_in_button {
  margin-top: 2.5rem;
  padding-top: 2rem;
  text-align: center;
  border-top: 1px solid var(--border);

  span {
    cursor: pointer;
    font-weight: bold;
    color: var(--primary);

    &:hover {
      text-decoration: underline;
    }
  }
}

.sign_error {
  font-size: 13px;
  color: var(--primary);
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .sign_up {
    padding-top: 2rem;
  }
}