:root {
  --default: rgb(97, 97, 97);
  --primary: rgb(255, 0, 0);
  --secondary: rgb(28, 116, 233);
  --negrito: rgb(45, 45, 45);
  --border: rgb(229, 229, 229);
  --purple: rgb(129, 9, 224);
  --dark-purple: rgb(98, 5, 172);
  --success: rgb(64, 205, 40);
}

body {
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-height: 100vh; */
  background-color: white;
}

* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  box-sizing: border-box;
}

.primary_color {
  color: var(--primary);
}

.primary_background {
  background-color: var(--primary);
}

.secondary_color {
  color: var(--secondary);
}

.secondary_background {
  background-color: var(--secondary);
}
a {
  color: var(--secondary);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888; 
}