.checkout_item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border);

  .image_container {
    width: 20%;
    height: auto;

    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }

  .description_block {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .name,
  .price,
  .quantity {
    font-size: 15px;
  }

  .name {
    margin-bottom: 1rem;
    text-transform: capitalize;
  }
  
  .price {
    font-weight: 600;
  }
  
  .quantity {
    width: 5%;
    display: flex;
    text-align: center;
    flex-direction: column-reverse;
    
    .value {
      margin: 0.6rem 0;
    }

    .arrow {
      font-size: 18px;
      cursor: pointer;

      &:first-child {
        transform: rotate(-90deg);
      }
      
      &:last-child {
        transform: rotate(-90deg);
      }
    }
  }
  
  .remove_button {
    width: auto;
    cursor: pointer;
    font-size: 13px;
    text-align: center;
    margin-left: 2vw;
    color: var(--default);
  }
}

@media (min-width: 600px) {
  .checkout_item {
    justify-content: space-around;

    .image_container {
      width: 110px;
    }

    .name,
    .price, 
    .quantity {
      font-size: 16px;
    }
    
    .remove_button {
      font-size: 18px;
    }
  }
}

@media (min-width: 960px) {
  .checkout_item {
    .description_block {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .name {
      margin-right: 5vw;
      margin-bottom: 0;
    }

    .quantity {
      flex-direction: row;
      align-items: center;
      
      .value {
        margin: 0 1.65rem;
      }
      
      .arrow {
        font-size: 22px;
        
        &:first-child, &:last-child {
          transform: rotate(0deg);
        }
      }
    }

    .remove_button {
      margin-left: 4vw;
    }
  }
}