.footer {
  padding: 2rem;
  margin-top: 2rem;
}

.footer_menu {
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--border);
}

.footer_social_icons {
  display: flex;
  padding: 1rem;
  margin-bottom: 2rem;
  justify-content: center;

  svg {
    width: 36px;
    height: 36px;
    margin: 0 1rem;
  }

  p {
    display: none;
  }
}

.footer_button {
  li {
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;
    text-align: center;
    border-radius: 5px;
    border: 1px solid var(--border);
  }
}

.footer_copyright {
  display: flex;
  font-size: 12px;
  margin-top: 2rem;
  text-align: center;
  padding-bottom: 10vh;
  flex-direction: column-reverse;

  p {
    color: var(--default);
    margin: 2rem 0;
  }
}

.footer_selos {
  display: flex;
  // cursor: pointer;
  align-items: center;
  justify-content: space-evenly;

  img {
    margin: 0.5rem;
  }
}

@media(min-width: 790px) {
  .footer_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer_social_icons {
    margin: 0;
  }

  .footer_button {
    display: flex;

    li {
      width: auto;
      margin: 0;
      border: none;
      padding: 0.5rem;
      font-size: 12px;
    }
  }

  .footer_copyright {
    padding-bottom: 2rem;
  }
}

@media (min-width: 1094px) {
  .footer_menu {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-bottom: 1rem;
    justify-content: space-between;
  }

  .footer_copyright {
    margin-top: 1rem;
    flex-direction: row;
    justify-content: space-between;
    
    .footer_selos {
      width: 50%;
      justify-content: space-between;
      
    }
  }
}