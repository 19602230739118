.modal_search_box {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .modal_search_box_title {
    margin-bottom: 1rem;
  }

  .search_box {
    display: flex;
    width: 70%;
  }
}