.close {
  display: none;
}

.modal {
  z-index: 1;
  width: 100%;
  height: 100%;
  color: white;
  position: fixed;
  background-color: var(--negrito);
}

.modal_close_button {
  left: 9vw;
  bottom: 9vh;
  
  font-size: 1rem;
  cursor: pointer;
  position: absolute;
  
  display: flex;
  align-items: center;
  
  span {
    font-size: 1.25rem;
    margin-right: 5px;
  }
}

.modal_content {
  height: 100%;
}

@media (min-width: 768px) {
  .modal {
    display: none;
  }
}