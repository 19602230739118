.successpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  
  hr {
    width: 90%;
    margin: 1rem auto;
    border: 1px solid var(--border);
  }
}

.successpage_header {
  display: flex;
  font-size: 1.8rem;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
  color: var(--success);
  
  span {
    font-size: 5rem;
    margin-bottom: 1.2rem;
  }
}

.successpage_order {
  text-align: center;
  overflow-wrap: anywhere;
}

.successpage_message {
  max-width: 90%;
  padding: 2rem;
  border-radius: 12px;
  word-wrap: break-word;
  border: 1px solid var(--border);

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.successpage_paragraph {
  margin-bottom: 1rem;
}

.successpage_button {
  padding: 1.2rem;
  cursor: pointer;
  margin-top: 1rem;
  border-radius: 12px;
  border: 1px solid var(--purple);

  &:hover {
    background-color: var(--purple);
    box-shadow: 0 4px 14px 0 rgba(129, 9, 224, 0.2);

    a {
      color: white;
      text-decoration: none;
    }
  }

  span {
    font-size: 1.4rem;
    margin-right: 0.5rem;
  }

  a {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: var(--purple);
    text-transform: uppercase;
  }
}

.text_highlight {
  color: var(--purple);
  font-style: italic;
}

@media (min-width: 1024px) {
  .successpage_header {
    font-size: 2rem;
    span {
      font-size: 6rem;
    }
  }
  .successpage_message {
    width: 55%;
  }
}