.notfoundpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2,
  p {
    margin-bottom: 1rem;
  }

  .notfoundpage_button {
    span {
      margin-right: 0.4rem;
    }

    a {
      display: flex;
      align-items: flex-end;
    }
  }
}
